<template>
  <div>
    <b-modal id="modalInfo" ref="myModalRef" centered :modal-class="ClassName" hide-footer>
      <template #modal-title>
        {{ $t("store.announcement") }}
      </template>
      <div class="tab-panel">
        <div class="tab-group">
          <div v-for="(item ,index) in tabData" class="tabnav" :key="index" :class="{ active: item.isActive }" @click="change(index)">{{ item.title }}</div>
        </div>

        <div class="panel-group">
          <div v-for="(item ,index) in tabData" class="panel" :key="index" :class="{ active: item.isActive }">
          <span>{{ item.title }}</span>
          <q>{{ item.date | useLocaleDate($i18n.locale) }}</q>
          <p v-html="replaceStr(item.content)"/>
          </div>
        </div>

        <b-button variant="submit" block @click="hideModal(); audioClick()" >{{ $t("store.btn.close") }}</b-button>

      </div>
    </b-modal>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapGetters } from "vuex";
  export default {
    name: "dialogMarquee",
    data() {
      return {
        first: true,
        myclass: ["dialog-notice"],
        tabData: []
      };
    },
    mounted() {
      this.tabData = this.noticeOK();
    },
    methods: {
      audioClick() {
        if(this.DEF_webStyle === 'jdf'|| this.DEF_webStyle === 'fl8n' || this.DEF_webStyle === 'fl88'|| this.DEF_webStyle === 'kb88' || this.DEF_webStyle === 'jh8'){
          if(this.IsAudio) {
            const audio = document.querySelector("#beep");
            const context = new AudioContext();
            function isIOS() {
              return /iPhone|iPad|iPod/i.test(navigator.userAgent);
            };
            if (context.state === 'suspended') {
              context.resume().then(() => {});
            };
            if (isIOS()) {
              setTimeout(function() {
                audio.muted = false;
                audio.play();
              }, 200);
            } else {
              audio.muted = false;
              audio.play();
            };
          }
        };
      },
      change(index){
        this.tabData.forEach( x=>x.isActive = false);
        this.tabData[index].isActive = true;
      },
      showModal: function () {
        this.$refs.myModalRef.show();
      },
      toggleModal() {
        this.$root.$emit("bv::toggle::modal", "modalInfo", "#btnToggle");
      },
      hideModal() {
        this.$refs.myModalRef.hide();
      },
      noticeOK() {
        return this.Announcement.map(function(Item, Index){
          return {
            date:  Item.date,
            title: Item.title,
            content : Item.content,
            created_at : Item.created_at,
            isActive : Index == 0
          }
        });
      },
      replaceStr(content) {
        if (content)
          return content.replace(/\n/g,'<br/>').replace(/ /g,' &nbsp')
        else
          return '';
      }
    },
    watch: {
      Announcement() {
        this.tabData = this.noticeOK();
      },
    },
    computed: {
      DEF_webStyle() {
        return process.env.DEF_webStyle || 'default';
      },
      ClassName() {
        let ModalClass = { 'dialog-notice': true };
        if (!this.FestivalClass)
          return ModalClass;

        return Object.assign(ModalClass, this.FestivalClass);
      },
      ...mapState(['WebSetting', 'Announcement', 'IsAudio']),
      ...mapGetters('webSetting', ['FestivalClass'])
    },
  }
</script>

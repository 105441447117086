<template>
  <div :class="['idex-wrap', 'desktop', ClassName, LocaleClass]">
    <button id="sound_btn" class="ignore ignore2" @click="sound_btn"></button>
    <pageHead device="desktop" page="layouts"/>
    <autoRefresh />
    <div class="btn-flexall">
      <!-- <div class="flex-left">
        <userModBtn v-for="(item, index) in flexLeft" v-model="flexLeft[index]" :key="index" v-if="item.type" />
      </div>
      <div class="flex-right">
        <userModBtn v-for="(item, index) in flexRight" v-model="flexRight[index]" :key="index" v-if="item.type" />
      </div> -->
      <userModBtn2 :value="floatBtn" />
    </div>
    <Header></Header>
    <main class="pageMain">
        <slot name="content">
          <pageHead device="desktop" page="index"/>
          <idxSlider />
          <idxGame />
          <idxJackpot />
          <idxDownload />
          <idxInfo />
        </slot>
        <idxMarquee />
        <div class="decLeft"></div>
        <div class="decLeft2"></div>
        <div class="decLeftBox">
          <div class="decItem1"></div>
          <div class="decItem2"></div>
          <div class="decItem3"></div>
          <div class="decItem4"></div>
        </div>
        <div class="decRight"></div>
        <div class="decRight2"></div>
        <div class="decRightBox">
          <div class="decItem1"></div>
          <div class="decItem2"></div>
          <div class="decItem3"></div>
          <div class="decItem4"></div>
          <div class="decItem5"></div>
        </div>
        <div class="decTop"></div>
        <div class="decBottom"></div>
        <div class="decBottom2"></div>
    </main>
    <Footer />
    <dialogMarquee />
    <dialogMaintain />
    <dialogExchPoint v-if="ShowTransferPoint" />
    <dialogTransferPoint v-if="ShowExchPoint" />
    <overlay v-model="IsLoading"></overlay>
    <upAgentCode />
    <audio id="beep" src="/audio/btnClick.mp3" type="audio/mp3" muted></audio>
    <slot name="other"/>
  </div>
</template>
<script>
  const Header = require(`~/components/desktop/${process.env.pc_header}.vue`).default;
  const idxMarquee = require(`~/components/desktop/${process.env.pc_marquee}.vue`).default;
  const idxGame = require(`~/components/desktop/${process.env.pc_game}.vue`).default;
  const idxJackpot = require(`~/components/desktop/${process.env.pc_jackpot}.vue`).default;
  const idxDownload = require(`~/components/desktop/${process.env.pc_idxDownload}.vue`).default;
  const idxInfo = require(`~/components/desktop/${process.env.pc_info}.vue`).default;
  const Footer = require(`~/components/desktop/${process.env.pc_footer}.vue`).default;

  import axios from "axios";
  let flexLeft = [];
  try { flexLeft = require("$json/flexLeft.json"); } catch (e) {}
  let flexRight = [];
  try { flexRight = require("$json/flexRight.json"); } catch (e) {}
  let floatBtn = [];
  try { floatBtn = require("$json/floatBtn.json"); } catch (e) {}
  import pageHead from "~/components/common/pageHead.vue";
  import autoRefresh from "~/components/common/autoRefresh.vue";
  import userModBtn from "~/components/desktop/userModBtn.vue";
  import userModBtn2 from "~/components/desktop/userModBtn2.vue";
  import idxSlider from "~/components/desktop/idxSlider2.vue";
  import dialogMarquee from "~/components/desktop/dialogMarquee.vue";
  import dialogMaintain from "~/components/desktop/dialogMaintain.vue";
  import dialogExchPoint from "~/components/desktop/dialogExchPoint.vue";
  import dialogTransferPoint from "~/components/desktop/dialogTransferPoint.vue";
  import overlay from "@/components/common/overlay.vue";
  import upAgentCode from "@/components/common/upAgentCode.vue";
  import { getJsonFile } from '~/utils/jsonFile';
  import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
  export default {
    components: {
      pageHead,
      autoRefresh,
      Header,
      userModBtn,
      userModBtn2,
      idxMarquee,
      idxSlider,
      idxGame,
      idxJackpot,
      idxDownload,
      idxInfo,
      Footer,
      dialogMaintain,
      dialogMarquee,
      dialogExchPoint,
      dialogTransferPoint,
      overlay,
      upAgentCode
    },
    data(){
      return{
        flexLeft,
        flexRight,
        darkMode: false,
        showFlexRight: true,
        floatBtn,
        LocaleClass: 'lang-'
      }
    },
    created() {
      if (this.flexRight == null  || this.flexRight.length == 0)
        this.flexRight = getJsonFile(process.env.DEF_webStyle, 'flexRight');
      if (this.floatBtn == null  || this.floatBtn.length == 0)
        this.floatBtn = getJsonFile(process.env.DEF_webStyle, 'floatBtn');
    },
    mounted() {
      this.$nextTick(function () {
        const sound_btn = document.getElementById('sound_btn');
        if (sound_btn) {
          sound_btn.click();
        }

        this.LocaleClass = 'lang-' + this.$i18n.locale;
      });
    },
    methods: {
      sound_btn(){
        if(this.DEF_webStyle === 'fl8n'|| this.DEF_webStyle === 'fl88'|| this.DEF_webStyle === 'jh8'){
          const btnLinks = document.querySelectorAll("button, a, .tab-group, .subm_group, .desktop-mar, ul.navbar, select, input, .close_selfbox-s, .overflow-auto, .custom-checkbox, .sound_on");
          const audio = document.querySelector("#beep");
          const context = new AudioContext();
          function isIOS() {
            return /iPhone|iPad|iPod/i.test(navigator.userAgent);
          };
          btnLinks.forEach((btnLink) => {
            if (btnLink.classList.contains("ignore")) {
              return;
            };
            btnLink.removeEventListener("click", () => {
              if(this.IsAudio == true){
                if (context.state === 'suspended') {
                  context.resume().then(() => {});
                };
                if (isIOS()) {
                  setTimeout(function() {
                    audio.muted = false;
                    audio.play();
                  }, 200);
                } else {
                  audio.muted = false;
                  audio.play();
                };
              };
            });
            btnLink.addEventListener("click", () => {
              if(this.IsAudio == true){
                if (context.state === 'suspended') {
                  context.resume().then(() => {});
                };
                if (isIOS()) {
                  setTimeout(function() {
                    audio.muted = false;
                    audio.play();
                  }, 200);
                } else {
                  audio.muted = false;
                  audio.play();
                };
              };
            });
          });
        };
      },
      scrollGoTop(){
        window.scrollTo({ top: 0 });
      }
    },
    watch: {
      "$route.path": function(path) {
        if(path !== '/'){
          this.scrollGoTop();
        }
      },
      "$i18n.locale": function(test) {
        this.LocaleClass = 'lang-' + this.$i18n.locale;
      }
    },
    computed: {
      DEF_webStyle() {
        return process.env.DEF_webStyle || 'default';
      },
      ShowTransferPoint() {
        return this.$auth.$state.loggedIn === true;
      },
      ShowExchPoint() {
        return this.$auth.$state.loggedIn === true && this.WebSetting.SHOW_CONVERSION === "1";
      },
      ClassName() {
        let ModalClass = { 'daynight': this.IsDayNight };
        if (!this.FestivalClass)
          return ModalClass;

        return Object.assign(ModalClass, this.FestivalClass);
      },
      ...mapState(['WebSetting', 'IsAudio', 'IsDayNight', 'IsLoading']),
      ...mapGetters('webSetting', ['FestivalClass'])
    }
  };
</script>
<style scoped>
  header{
    width: 100%;
  }
</style>
